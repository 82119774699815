import { registerApplication, start, LifeCycles } from "single-spa";

function showWhenAnyOf(routes: string[]) {
  return function (location: Location) {
    return routes.some((route) => location.pathname === route);
  };
}

function showWhenPrefix(routes: string[]) {
  return function (location: Location) {
    return routes.some((route) => location.pathname.startsWith(route));
  };
}

function showExcept(routes: string[]) {
  return function (location: Location) {
    return routes.every((route) => location.pathname !== route);
  };
}

function loadApp(name: string) {
  return () => System.import(name) as Promise<LifeCycles>;
}

registerApplication(
  "@froneus/evi-nav-user-menu",
  loadApp("@froneus/evi-nav-user-menu"),
  showWhenPrefix([
    "/inicio",
    "/listado",
    "/reporte",
    "/detalle",
    "/llamado",
    "/monitoring",
    "/campaign-crud",
    "/voicebot",
    "/configuration",
    "/config-site",
    "/config-calls",
    "/config-voicebots",
    "/config-audits",
    "/audit",
  ])
);

registerApplication(
  "@froneus/evi-login",
  loadApp("@froneus/evi-login"),
  showWhenAnyOf(["/", "/login"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-home",
  loadApp("@froneus/evi-home"),
  showWhenAnyOf(["/inicio"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-campaign-list",
  loadApp("@froneus/evi-campaign-list"),
  showWhenPrefix(["/listado"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-campaign-report",
  loadApp("@froneus/evi-campaign-report"),
  showWhenAnyOf(["/reporte"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-campaign-detail",
  loadApp("@froneus/evi-campaign-detail"),
  showWhenAnyOf(["/detalle"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-surveyresponse-detail",
  loadApp("@froneus/evi-surveyresponse-detail"),
  showWhenAnyOf(["/llamado"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-monitoring",
  loadApp("@froneus/evi-monitoring"),
  showWhenAnyOf(["/monitoring"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-voicebot",
  loadApp("@froneus/evi-voicebot"),
  showWhenAnyOf(["/voicebot"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-configuration",
  loadApp("@froneus/evi-configuration"),
  showWhenAnyOf(["/configuration"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-config-site",
  loadApp("@froneus/evi-config-site"),
  showWhenAnyOf(["/config-site"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-config-calls",
  loadApp("@froneus/evi-config-calls"),
  showWhenAnyOf(["/config-calls"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-config-voicebots",
  loadApp("@froneus/evi-config-voicebots"),
  showWhenAnyOf(["/config-voicebots"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-config-audits",
  loadApp("@froneus/evi-config-audits"),
  showWhenAnyOf(["/config-audits"]),
  { some: "value" }
);

registerApplication(
  "@froneus/evi-audit",
  loadApp("@froneus/evi-audit"),
  showWhenAnyOf(["/audit"]),
  { some: "value" }
);

System.import("@froneus/evi-utility-styleguide").then(() => {
  start();
});
